export const AllTeamLogos = {
  Arizona_Cardinals: require("./arizona_cardinals.png"),
  Atlanta_Falcons: require("./atlanta_falcons.png"),
  Baltimore_Ravens: require("./baltimore_ravens.png"),
  Buffalo_Bills: require("./buffalo_bills.png"),
  Carolina_Panthers: require("./carolina_panthers.png"),
  Chicago_Bears: require("./chicago_bears.png"),
  Cincinnati_Bengals: require("./cincinnati_bengals.png"),
  Cleveland_Browns: require("./cleveland_browns.png"),
  Dallas_Cowboys: require("./dallas_cowboys.png"),
  Denver_Broncos: require("./denver_broncos.png"),
  Detroit_Lions: require("./detroit_lions.png"),
  Green_Bay_Packers: require("./green_bay_packers.png"),
  Houston_Texans: require("./houston_texans.png"),
  Indianapolis_Colts: require("./indianapolis_colts.png"),
  Jacksonville_Jaguars: require("./jacksonville_jaguars.png"),
  Kansas_City_Chiefs: require("./kansas_city_chiefs.png"),
  Las_Vegas_Raiders: require("./las_vegas_raiders.png"),
  Los_Angeles_Chargers: require("./los_angeles_chargers.png"),
  Los_Angeles_Rams: require("./los_angeles_rams.png"),
  Miami_Dolphins: require("./miami_dolphins.png"),
  Minnesota_Vikings: require("./minnesota_vikings.png"),
  New_England_Patriots: require("./new_england_patriots.png"),
  New_Orleans_Saints: require("./new_orleans_saints.png"),
  New_York_Giants: require("./new_york_giants.png"),
  New_York_Jets: require("./new_york_jets.png"),
  Philadelphia_Eagles: require("./philadelphia_eagles.png"),
  Pittsburgh_Steelers: require("./pittsburgh_steelers.png"),
  San_Francisco_49ers: require("./san_francisco_49ers.png"),
  Seattle_Seahawks: require("./seattle_seahawks.png"),
  Tampa_Bay_Buccaneers: require("./tampa_bay_buccaneers.png"),
  Tennessee_Titans: require("./tennessee_titans.png"),
  Washington_Commanders: require("./washington_commanders.png"),
};
