export const TEASER_LEG_ODDS = {
  6.0: 1.38461,
  6.5: 1.35714,
  7.0: 1.32258,
  7.5: 1.28571,
  8.0: 1.26666,
  8.5: 1.25,
  9.0: 1.22222,
  9.5: 1.21276,
  10.0: 1.2,
};

export const BUY_POINTS_ODDS = {
  0.5: 1.8,
  1.0: 1.66667,
  1.5: 1.57143,
  2.0: 1.5,
};
